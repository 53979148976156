import 'date-fns';
import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import WorkerSearch from './WorkerSearch';
import AutoEditor from '../auto/AutoEditor';
import WorkerHistory from '../training/WorkerHistory';
import DefaultAutoGrid from '../auto/DefaultAutoGrid';
import { AutoLoadKeyDisplay } from '../auto/AutoLoad';
import { createStyles, withStyles } from '@mui/styles';
import WorkerSchedule from '../training/WorkerSchedule';
import { useQueryParams } from '../../abstracts/NavigationHelpers';
import { workerWebAssignLink } from '../training/WorkerWebSchedule';
import { AppBar, Grid, Paper, Tabs, Tab, Theme, useTheme } from '@mui/material';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import { IConnectedProps, mapConfigFetchToProps } from '../auto/AutoGrid';
import { handleWorkerMerge } from '../../stores/database/identity/workers';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '10px 16px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  centerGrid: {
    textAlign: 'center'
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  },
  pictureGrid: {
    minWidth: 200
  }
});

interface IWorkerViewProps {
  classes: any;
  personId?: number;
}

const tabs = ['history', 'companies', 'scheduled', 'webassigned', 'merged', 'cards', 'custom'] as const;
type TabValues = typeof tabs[number];

interface IWorkerViewEditState {
  loadedTabs: TabValues[];
  forceRefresh: Dictionary<number>;
}

function WorkerView(props: IWorkerViewProps & IConnectedProps) {
  const theme = useTheme();
  const { classes, personId } = props;

  const [{ tab }, setParams] = useQueryParams<{ tab: TabValues }>({
    tab: {
      type: 'string',
      defaultValue: typeof personId === 'undefined' ? undefined : 'history',
      allowedValues: JSON.parse(JSON.stringify(tabs))
    }
  }, 'worker');

  const [editState, setEditState] = React.useState<IWorkerViewEditState>({
    loadedTabs: [],
    forceRefresh: {}
  });

  React.useEffect(() => setEditState({
    loadedTabs: [tab],
    forceRefresh: {}
  }), [personId]);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: TabValues) => {
    setEditState((old) => ({
      ...old,
      loadedTabs: [newValue].concat(old.loadedTabs)
    }));

    setParams({ tab: newValue });
  };

  const handleEditSave = () => { };
  const handleEditCancel = () => { };

  const handlePersonMerge = (workers: AutoLoadKeyDisplay) => personId != null && handleWorkerMerge({ fromWorkerId: +Object.keys(workers)[0], toWorkerId: +personId }).then(() => setEditState((old) => ({
    ...old,
    forceRefresh: {
      ...old.forceRefresh,
      merged: moment().valueOf()
    }
  })));

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <div className={classes.contentWrapper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AutoEditor
                baseTable="identity.person"
                editId={props.personId}
                mode="inline"
                minHeight={300}
                onSave={handleEditSave}
                onCancel={handleEditCancel}
              />
            </Grid>
            {personId == null ? undefined :
              <Grid className={classes.root} item xs={12}>
                <AppBar sx={{ mb: theme.spacing(1) }} position="relative">
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    aria-label="Worker details options"
                  >
                    <Tab label="Training History" value="history" />
                    <Tab label="Company Association" value="companies" />
                    {props.profile.isTrainingFacility || props.profile.personId === props.personId ? <Tab label="Merged IEC Numbers" value="merged" /> : undefined}
                    {props.profile.isTrainingFacility ? <Tab label="Printed Cards" value="cards" /> : undefined}
                    <Tab label="Scheduled Training" value="scheduled" />
                    <Tab label="Web Assigned Training" value="webassigned" />
                  </Tabs>
                </AppBar>
                {editState.loadedTabs.indexOf('history') < 0 ? undefined :
                  <WorkerHistory
                    hideWorkerName
                    hidden={tab !== 'history'}
                    personIds={personId}
                    maxHeight="calc(100vh - 350px)"
                  />}
                {editState.loadedTabs.indexOf('companies') < 0 ? undefined : (
                  <div style={{ maxWidth: '100vw' }} hidden={tab !== 'companies'}>
                    <DefaultAutoGrid
                      tableName="identity.personCompany"
                      defaultColumnFilters={[{ column: 'person', filter: personId, hideFromTable: true, defaultInEditor: 'readonly' }]}
                      autoEditor={props.profile.isTrainingFacility ? true : { filters: { company: props.profile.companies.map((x) => x.companyId) } }}
                      autoDelete={props.profile.isTrainingFacility}
                      pagination
                    />
                  </div>
                )}
                {editState.loadedTabs.indexOf('merged') < 0 ? undefined : (
                  <div style={{ maxWidth: '100vw' }} hidden={tab !== 'merged'}>
                    <WorkerSearch mode="button" onWorkersSelected={handlePersonMerge} dialogTitle="Merge Worker" buttonTitle="Merge Worker" excludedIds={[+personId]} limitSelectCount={1} />
                    <DefaultAutoGrid
                      tableName="identity.person"
                      columns={['iecNumber', 'firstLegal', 'firstPreferred', 'middle', 'last', 'addressLine1', 'addressLine2', 'addressCity']}
                      quickFilter={[
                        { path: 'worker.person', value: personId },
                        { path: 'iecNumber', not: true, value: personId }
                      ]}
                      pagination
                      forceUpdateStamp={editState.forceRefresh.merged}
                    />
                  </div>
                )}
                {editState.loadedTabs.indexOf('cards') < 0 ? undefined : (
                  <div style={{ maxWidth: '100vw' }} hidden={tab !== 'cards'}>
                    <DefaultAutoGrid tableName="identity.personCard" columns={['person.iecNumber', 'lookupKey', 'createDateUTC', 'printDateUTC', 'printStatus']} quickFilter={[{ path: 'person.worker.person', value: personId }]} pagination />
                  </div>
                )}
                {editState.loadedTabs.indexOf('scheduled') < 0 ? undefined : <WorkerSchedule hidden={tab !== 'scheduled'} personIds={personId} maxHeight="calc(100vh - 350px)" />}
                {editState.loadedTabs.indexOf('webassigned') < 0 ? undefined : (
                  <div style={{ maxWidth: '100vw' }} hidden={tab !== 'webassigned'}>
                    <DefaultAutoGrid
                      tableName="training.workerWebAssign"
                      columns={['course.title', 'userCompany.company.name', 'userCompany.user.displayName', 'userCompany.user.phoneNumber', 'startDateUTC', 'completeDateUTC', 'prerequisiteTo', workerWebAssignLink]}
                      quickFilter={[{ path: 'person.worker.person', value: personId }]}
                      autoDelete={{
                        filters: {
                          'connectedWorkerSchedule.courseSchedule.trainingDate': undefined,
                          completeDateUTC: undefined
                        },
                        hideByProfile: p => !p.isTrainingFacility
                      }}
                      pagination
                    />
                  </div>
                )}
              </Grid>
            }
          </Grid>
        </div>
      </Paper>
    </React.Fragment>
  );
}

export default compose(withStyles(styles), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(WorkerView);
