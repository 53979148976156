import { IAppState } from '../../';
import { Dictionary } from 'lodash';
import { connectHub } from './reducer';
import { Database } from '../../database/class';
import { HubConnection } from '@microsoft/signalr';
import { IFilterGroup } from '../../database/interfaces';
import { IAutoGridProps } from '../../../components/auto/AutoGrid';
import { all, fork, takeEvery, put, select, call } from 'redux-saga/effects';
import { courseScheduleConfig } from '../../database/training/courseSchedule';
import { arrayOuterDifferences, objectEach, objectMapArray } from '../../../abstracts/DataroweHelpers';
import { CheckinActionTypes, IFilterConfig, CheckinColumnPaths, ICourseScheduleConnect, PageType } from './types';
import { IFilterDefinition, FilterGroupType, FilterConditionType, ISelectColumnMap } from '../../database/interfaces';
import { autoLoad, AutoRow, getAllFields, IAutoLoadCacheConnected, quickLoad } from '../../../components/auto/AutoLoad';
import { loadCourseCache, IFitTestSchedule, formatFitTestSchedule, ICourseCacheLoad } from '../../database/training/courses';
import { updateCheckinTableData, updateCheckinDate, ICheckinHub, persistConnection, mergeQuickAddWorkerSchedules } from './actions';

const hubState = (state: IAppState) => ({
  hub: state.checkin.hubConnection
});

function* handleCheckinConfigLoad(action: { type: CheckinActionTypes, payload: { checkinHub: ICheckinHub, connected: IAutoLoadCacheConnected, type: PageType, gridParams: Omit<IAutoGridProps, 'rowData'>; filterProps: IFilterConfig; date?: string; } }) {
  const { checkinHub, connected, date, type } = action.payload;

  if (date == null) {
    yield put(updateCheckinTableData({}, type));
  } else {
    yield put(updateCheckinDate(checkinHub, connected, type, date));
  }
}

function* handleCheckinFilterUpdate(action: { type: CheckinActionTypes, payload: { checkinHub: ICheckinHub, connected: IAutoLoadCacheConnected; type: PageType, date: string } }) {
  let { hub }: { hub: HubConnection } = yield select(hubState);
  const { checkinHub, connected, date, type } = action.payload;

  if (hub == null) {
    hub = yield connectHub(checkinHub);
    yield put(persistConnection(hub!));
    // yield put(signalrHubCheckinConnect(checkinHub, date));
  }

  const [fields, existingSchedules]: [IFilterConfig, ICourseScheduleConnect[]] = yield select((state: IAppState) => [state.checkin[`${type}FilterProps`], state.checkin.connectedCourseSchedules]);

  const params: Omit<IAutoGridProps<AutoRow, ISelectColumnMap>, 'rowData'> = yield select((state: IAppState) => state.checkin[`${type}GridParams`]);

  const baseFilter: IFilterGroup = {
    type: FilterGroupType.And,
    children: [
      {
        columnId: fields.uploadField.columnId,
        lookupPath: fields.uploadField.lookupPath || [],
        type: FilterConditionType.Equal,
        firstParameter: false
      },
      {
        columnId: fields.dateField.columnId,
        lookupPath: fields.dateField.lookupPath || [],
        forcedType: 'Date',
        type: FilterConditionType.Equal,
        firstParameter: date
      }
    ]
  };

  if (type === 'fittest') {
    baseFilter.children.push({
      columnId: fields.scheduleTypeField.columnId,
      lookupPath: fields.scheduleTypeField.lookupPath || [],
      type: FilterConditionType.Equal,
      firstParameter: 'fittest'
    });
  }

  const search: IFilterDefinition = {
    baseTableId: +params.baseTable!,
    name: '',
    baseFilter
  };

  const courses: ICourseCacheLoad = yield call(loadCourseCache, connected.refreshCacheData, connected.fetchConfigRequest, connected.createAlertBulk);

  const db: Database = yield select((state: IAppState) => state.db.database);

  const schedules: Dictionary<ICourseScheduleConnect> = yield call(quickLoad, {
    db,
    schema: 'training',
    table: 'courseSchedule',
    keyfield: 'courseScheduleId',
    columns: {
      name: 'CourseLocationDisplay',
      id: 'courseScheduleId',
      scheduleType: courseScheduleConfig.seatType
    },
    filters: {
      trainingDate: date,
      recordsUpload: false,
      [courseScheduleConfig.seatType]: type == 'checkin' ? 'classroom' : 'fittest'
    }
  });

  const rows: Dictionary<AutoRow> = yield call(autoLoad, {
    search,
    fetchConfigRequest: connected.fetchConfigRequest,
    createAlertBulk: connected.createAlertBulk,
    baseTableId: +params.baseTable!,
    keyField: params.keyField!,
    displayField: params.displayField!,
    allFields: getAllFields(params.displayColumns, params.additionalColumns)
  });


  objectEach(rows, (_key, row) => {
    if (row[CheckinColumnPaths.scheduleTypeKey] === 'fittest' && row[CheckinColumnPaths.scheduleTimeslot] != null) {
      const scheduled: IFitTestSchedule[] = JSON.parse(row[CheckinColumnPaths.scheduleTimeslot]);
      row[CheckinColumnPaths._generatedDisplay] = scheduled.map(schedule => formatFitTestSchedule(schedule, courses.courseRows));
    } 
  });


  const updatedSchedules = objectMapArray(schedules, (_key, value) => value);

  const [removeIds, addIds] = arrayOuterDifferences(existingSchedules.map(eSchedule => eSchedule.id), updatedSchedules.map(uSchedule => uSchedule.id));

  hub.invoke('ConnectCheckinRange', addIds, removeIds);

  yield put(updateCheckinTableData(rows, type, search, courses.courseRows, type === 'fittest' ? updatedSchedules : []));
}

function* handleQuickAddWorkersResult(action: { type: CheckinActionTypes, payload: { connected: IAutoLoadCacheConnected, workerScheduleIds: number[] } }) {
  const { connected, workerScheduleIds } = action.payload;

  const fields: IFilterConfig = yield select((state: IAppState) => state.checkin.fittestFilterProps);

  const params: Omit<IAutoGridProps<AutoRow, ISelectColumnMap>, 'rowData'> = yield select((state: IAppState) => state.checkin.fittestGridParams);
  const search: IFilterDefinition = {
    baseTableId: +params.baseTable!,
    name: '',
    baseFilter: {
      columnId: fields.workerScheduleField.columnId,
      lookupPath: fields.workerScheduleField.lookupPath || [],
      type: FilterConditionType.IsOneOf,
      firstParameter: workerScheduleIds
    }
  };

  const courses: ICourseCacheLoad = yield call(loadCourseCache, connected.refreshCacheData, connected.fetchConfigRequest, connected.createAlertBulk);

  const rows: Dictionary<AutoRow> = yield call(autoLoad, {
    search,
    fetchConfigRequest: connected.fetchConfigRequest,
    createAlertBulk: connected.createAlertBulk,
    baseTableId: +params.baseTable!,
    keyField: params.keyField!,
    displayField: params.displayField!,
    allFields: getAllFields(params.displayColumns, params.additionalColumns)
  });

  objectEach(rows, (_key, row) => {
    if (row[CheckinColumnPaths.scheduleTypeKey] === 'fittest' && row[CheckinColumnPaths.scheduleTimeslot] != null) {
      const scheduled: IFitTestSchedule[] = JSON.parse(row[CheckinColumnPaths.scheduleTimeslot]);
      row[CheckinColumnPaths._generatedDisplay] = scheduled.map(schedule => formatFitTestSchedule(schedule, courses.courseRows));
    }
  });

  yield put(mergeQuickAddWorkerSchedules(rows));
}

function* handleCheckinCourse(action: { type: CheckinActionTypes, payload: { workerScheduleId: number, key: string, newValue: boolean } }) {
  const { hub }: { hub: HubConnection } = yield select(hubState);

  hub.invoke('CheckinCourse', action.payload.workerScheduleId, action.payload.newValue).catch(e => console.log(e));
}

function* handleToggleWHMIS(action: { type: CheckinActionTypes, payload: { personId: number, key: string, newValue: boolean } }) {
  const { hub }: { hub: HubConnection } = yield select(hubState);

  hub.invoke('CheckinToggleWHMIS', action.payload.personId, action.payload.newValue).catch(e => console.log(e));
}

function* handleToggleVerified(action: { type: CheckinActionTypes, payload: { personId: number, key: string, newValue: boolean } }) {
  const { hub }: { hub: HubConnection } = yield select(hubState);

  hub.invoke('CheckinToggleVerified', action.payload.personId, action.payload.newValue).catch(e => console.log(e));
}

function* watchCheckinConfigLoad() {
  yield takeEvery(CheckinActionTypes.LOAD_CONFIG, handleCheckinConfigLoad);
}

function* watchCheckinFilterUpdate() {
  yield takeEvery(CheckinActionTypes.LOAD_FILTER, handleCheckinFilterUpdate);
}

function* watchHandleQuickAddWorkersResult() {
  yield takeEvery(CheckinActionTypes.LOAD_QUICK_ADD_WORKER_SCHEDULES, handleQuickAddWorkersResult);
}

function* watchCheckinCourse() {
  yield takeEvery(CheckinActionTypes.CHECKIN_COURSE, handleCheckinCourse);
}

function* watchToggleWHMIS() {
  yield takeEvery(CheckinActionTypes.CHECKIN_TOGGLEWHMIS, handleToggleWHMIS);
}

function* watchToggleVerified() {
  yield takeEvery(CheckinActionTypes.CHECKIN_TOGGLEVERIFIED, handleToggleVerified);
}

export function* checkinSaga() {
  yield all([
    fork(watchCheckinConfigLoad),
    fork(watchCheckinFilterUpdate),
    fork(watchHandleQuickAddWorkersResult),
    fork(watchCheckinCourse),
    fork(watchToggleWHMIS),
    fork(watchToggleVerified)
  ]);
}


