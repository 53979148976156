import React from 'react';
import { compose } from 'redux';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IAppState } from '../../../stores';
import { Theme, Typography } from '@mui/material';
import WorkerPhoto from '../../identity/WorkerPhoto';
import { createStyles, withStyles } from '@mui/styles';
import { createAlertBulk } from 'src/stores/ui/actions';
import { Database } from '../../../stores/database/class';
import { LocalFilters } from '../../auto/AutoLocalFilter';
import { IProfileState } from '../../../stores/profile/types';
import { IDatabaseState } from '../../../stores/database/types';
import { customNavigate } from '../../../abstracts/NavigationHelpers';
import { FilterConditionType } from '../../../stores/database/interfaces';
import { RowFormatter } from 'src/stores/database/gridExtension/interfaces';
import { fetchConfigRequest, refreshCacheData } from '../../../stores/database/actions';
import { CheckinColumnPaths, ICheckinState } from '../../../stores/training/checkin/types';
import { ICustomColumnDefinition, AutoRow, IAutoLoadCacheConnected } from '../../auto/AutoLoad';
import WorkerSchedulePrereqStatus, { prereqStatusListDisplay } from '../WorkerSchedulePrereqStatus';
import AutoGrid, { mapConfigFetchToProps, RowClasses, AutoGridRowClickProps } from '../../auto/AutoGrid';
import { IFitTestSchedule, ICourse, formatFitTestScheduleTitle } from '../../../stores/database/training/courses';
import { loadCheckinConfig, updateCheckinDate, signalrHubCheckinWorker, signalrHubUpdateWHMIS, signalrHubUpdateVerified, ICheckinHub, mapCheckinHub, hubClear, loadQuickAddWorkerSchedules } from '../../../stores/training/checkin/actions';

const styles = (theme: Theme) => createStyles({
  incompleteTraining: {
    backgroundColor: theme.palette.grey[100]
  }
});

interface IFittestProps {
  classes: any;
  checkinDate?: string;
  courseScheduleId?: number;
  personId?: number;
  minHeight?: number | string;
  maxHeight?: number | string;
  onRowSelected?: (workerScheduleId: number, personId: number) => void;
  quickFilter?: string;
}

export interface IFittestConnectedProps extends ICheckinHub {
  db: IDatabaseState;
  profile: IProfileState;
  checkin: ICheckinState;
  loadCheckinConfig: typeof loadCheckinConfig;
  createAlertBulk: typeof createAlertBulk;
  updateCheckinDate: typeof updateCheckinDate;
  fetchConfigRequest: typeof fetchConfigRequest;
  refreshCacheData: typeof refreshCacheData;
  signalrHubCheckinWorker: typeof signalrHubCheckinWorker;
  signalrHubUpdateWHMIS: typeof signalrHubUpdateWHMIS;
  signalrHubUpdateVerified: typeof signalrHubUpdateVerified;
}

const FitTestList = (props: IFittestProps & IFittestConnectedProps) => {
  const { user, checkin } = props;
  const { toWorker } = customNavigate;

  const checkinHub: ICheckinHub = {
    onPersonUpdateWHMIS: props.onPersonUpdateWHMIS,
    onPersonUpdateVerified: props.onPersonUpdateVerified,
    onScheduleUpdateArrival: props.onScheduleUpdateArrival,
    onScheduleUpdateStatus: props.onScheduleUpdateStatus,
    user
  };

  const cacheConnected: IAutoLoadCacheConnected = {
    fetchConfigRequest: props.fetchConfigRequest,
    createAlertBulk: props.createAlertBulk,
    refreshCacheData: props.refreshCacheData
  };

  const displayField: RowFormatter<string> = (row): string => `${row[CheckinColumnPaths.personDisplayName]} (${row[CheckinColumnPaths.personId]})`;

  const displayCourseTitles = (row: AutoRow, courses: Dictionary<ICourse>): string => {
    if (row[CheckinColumnPaths.scheduleTypeKey] === 'fittest' && row[CheckinColumnPaths.scheduleTimeslot] != null) {
      const scheduled: IFitTestSchedule[] = JSON.parse(row[CheckinColumnPaths.scheduleTimeslot]);

      return scheduled.map((schedule) => formatFitTestScheduleTitle(schedule, courses)).join('\n');
    }

    return row[CheckinColumnPaths.scheduleCourseTitle];
  };

  const handleCourseDisplay = (row: AutoRow, _key: string | number, _course?: any, schedule?: any) => {
    return <React.Fragment key={row[CheckinColumnPaths.workerScheduleId]}>
      {(JSON.parse(row[CheckinColumnPaths.scheduleTimeslot]) as IFitTestSchedule[] ?? []).map(sched =>
        <Typography key={sched.scheduleType}>{formatFitTestScheduleTitle(sched, schedule.courses)}</Typography>
      )}
    </React.Fragment>;
  };

  const defaultColumns = (): ICustomColumnDefinition[] => [
    {
      key: CheckinColumnPaths.personPictureTakenUTC,
      title: 'Photo',
      displayControl: row => <WorkerPhoto personId={row[CheckinColumnPaths.personId]} photoTakenUTC={row[CheckinColumnPaths.personPictureTakenUTC]} diameter={80} />
    },
    {
      key: CheckinColumnPaths.personId,
      title: 'Worker',
      display: (row) => `${row[CheckinColumnPaths.personDisplayName]} (${row[CheckinColumnPaths.personId]})`,
      displayControl: row => <Link style={{ color: '#3391FF' }} to={toWorker(row[CheckinColumnPaths.personId])}>{row[CheckinColumnPaths.personDisplayName]} ({row[CheckinColumnPaths.personId]})</Link>
    },
    {
      key: CheckinColumnPaths.prereqStatus,
      cellAlign: 'center',
      display: (row) => prereqStatusListDisplay(JSON.parse(row[CheckinColumnPaths.prereqStatus])).join('\n'),
      displayControl: (row) => (
        <WorkerSchedulePrereqStatus
          personId={row[CheckinColumnPaths.personId]}
          personDisplayName={row[CheckinColumnPaths.personDisplayName]}
          courseDisplayName={row[CheckinColumnPaths.scheduleCourseTitle]}
          courseDescription={row[CheckinColumnPaths._generatedDisplay]}
          workerScheduleId={row[CheckinColumnPaths.workerScheduleId]}
          statuses={JSON.parse(row[CheckinColumnPaths.prereqStatus])}
        />
      )
    },
    {
      key: CheckinColumnPaths.company,
      title: 'Company'
    },
    {
      key: CheckinColumnPaths.scheduleCourseTitle,
      title: 'Course Title',
      display: (row, _key, _column, schedule) => displayCourseTitles(row, schedule.courses),
      displayControl: handleCourseDisplay
    },
    {
      key: CheckinColumnPaths.trainingTime
    },
    {
      key: CheckinColumnPaths.locationName
    }
  ];

  const extraColumns = [
    CheckinColumnPaths.workerScheduleId,
    CheckinColumnPaths.courseScheduleId,
    CheckinColumnPaths.scheduleCourseTitle,
    CheckinColumnPaths.personPictureTakenUTC,
    CheckinColumnPaths.trainingTime,
    CheckinColumnPaths.companyId,
    CheckinColumnPaths.checkinDate,
    CheckinColumnPaths.scheduleStatus,
    CheckinColumnPaths.scheduleTimeslot,
    CheckinColumnPaths.scheduleTypeKey,
    CheckinColumnPaths.personDisplayName
  ];

  const handleRowClick = (e: AutoGridRowClickProps) => {
    if (props.onRowSelected != null) props.onRowSelected(checkin.fittestRowData[e.id][CheckinColumnPaths.workerScheduleId], checkin.fittestRowData[e.id][CheckinColumnPaths.personId]);
  };

  const loadConfig = () => {
    if (checkin.fittestFilterProps == null) {
      props.fetchConfigRequest(undefined, {
        key: 'fitTestTrainingCheckinPage',
        action: (db) => {
          if (checkin.fittestGridParams.baseTable == null) return;
          const baseTable = typeof checkin.fittestGridParams.baseTable === 'string' ? db.getTableByCombinedName(checkin.fittestGridParams.baseTable.toString()).tableId : +checkin.fittestGridParams.baseTable;
          const [dateField, keyField, uploadField, scheduleTypeField, workerScheduleField] = db.mapSelectColumnsByNamesWithId(baseTable, [CheckinColumnPaths.scheduleDate, CheckinColumnPaths.workerScheduleId, CheckinColumnPaths.recordsUpload, CheckinColumnPaths.scheduleTypeKey, CheckinColumnPaths.workerScheduleId]);
          const displayColumns = db.mapDisplayColumnsByNamesWithId(baseTable, defaultColumns());
          const additionalColumns = db.mapSelectColumnsByNamesWithId(baseTable, extraColumns);

          const gridParams = {
            ...checkin.fittestGridParams,
            baseTable,
            keyField,
            displayField,
            displayColumns,
            additionalColumns
          };

          const filterProps = {
            dateField,
            uploadField,
            scheduleTypeField,
            workerScheduleField,
            additionalColumns
          };

          props.loadCheckinConfig(checkinHub, cacheConnected, 'fittest', gridParams, filterProps, props.checkinDate);
        }
      });
    }
  };

  React.useEffect(() => {
    loadConfig();
    return () => {};
  }, []);

  React.useEffect(() => {
    loadConfig();
    return () => {};
  }, [props.checkinDate, props.courseScheduleId]);

  React.useEffect(() => {
    if (props.checkin.hubConnection != null && props.checkinDate != null) {
      props.updateCheckinDate(checkinHub, cacheConnected, 'fittest', props.checkinDate);
    }
    return () => {};
  }, [props.checkinDate]);

  const rowClasses: RowClasses = (data) => (data[CheckinColumnPaths.checkinDate] == null ? [props.classes.notCheckedIn] : [props.classes.CheckedIn]);

  const columnFilter = () => {
    if (props.courseScheduleId == null) return undefined;

    const filter: Dictionary<LocalFilters> = {
      [CheckinColumnPaths.courseScheduleId]: {
        type: 'numberFilter',
        filter: FilterConditionType.Equal,
        firstParameter: props.courseScheduleId
      }
    };

    if (props.personId != null) {
      filter[CheckinColumnPaths.personId] = {
        type: 'numberFilter',
        filter: FilterConditionType.Equal,
        firstParameter: props.personId
      };
    }

    return filter;
  };

  return (
    <AutoGrid
      minHeight={props.minHeight}
      maxHeight={props.maxHeight}
      quickTextFilter={props.quickFilter}
      {...checkin.fittestGridParams}
      rowData={checkin.fittestRowData}
      defaultSortColumn={5}
      rowClasses={rowClasses}
      rowDataLoading={checkin.fittestRowDataLoading}
      rowDataLastUpdate={checkin.fittestRowDataLastUpdate}
      onRowClick={handleRowClick}
      stateExtension={{ courses: checkin.courses }}
      columnFilter={columnFilter()}
    />
  );
};

export const mapFromAppState = ({ db, profile, oidc, checkin }: IAppState) => ({
  profile,
  checkin,
  db: {
    ...db,
    database: new Database(db.database)
  },
  user: oidc.user
});

export const mapCheckinActions = {
  ...mapConfigFetchToProps,
  ...mapCheckinHub,
  loadCheckinConfig,
  updateCheckinDate,
  signalrHubCheckinWorker,
  signalrHubUpdateWHMIS,
  signalrHubUpdateVerified,
  hubClear,
  loadQuickAddWorkerSchedules
};

export default compose(withStyles(styles), connect(mapFromAppState, mapCheckinActions))(FitTestList);
