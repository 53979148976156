import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import WorkerPhoto from './WorkerPhoto';
import settings from '../../abstracts/settings';
import LoadingScreen from '../common/LoadingScreen';
import { Forward, Search } from '@mui/icons-material';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import { objectMap, objectMapArray, quickGrid, stringToIdList } from '../../abstracts/DataroweHelpers';
import { FilterConditionType, IFilterDefinition, ISelectColumnMap } from '../../stores/database/interfaces';
import { autoLoad, ICustomColumnDefinition, getAllFields, AutoRow, AutoLoadKeyDisplay } from '../../components/auto/AutoLoad';
import AutoGrid, { IAutoGridProps, mapConfigFetchToProps, IConnectedProps, AutoGridRowClickEvent } from '../../components/auto/AutoGrid';
import { TextField, IconButton, Button, InputAdornment, Tooltip, Theme, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const generateSxClasses = (_theme: Theme) => {
  return {
    buttonAlign: {
      alignContent: 'center',
      display: 'flex',
      flexWrap: 'wrap'
    }
  };
};

type GridStateType = IAutoGridProps<AutoRow, ISelectColumnMap, ISelectColumnMap> & { idSearchField: ISelectColumnMap };

interface IWorkerListProps extends Omit<IConnectedProps, 'classes'> {
  maxHeight?: string | number;
  disabled?: boolean;
  quickFilter?: string;
  limitSelectCount?: number;
  excludedIds?: number[];
  onWorkersSelected: (workers?: AutoLoadKeyDisplay, filterOverride?: string) => void;
  onQuickFilter?: (filter: string | undefined) => void;
  onAddNew: () => void;
}

export interface IWorkerListButtonProps extends IWorkerListProps {
  mode: 'button';
  open: boolean;
  buttonTitle: string;
  dialogTitle?: string;
  onNoWorkerSelect?: () => void;
  onCancel?: () => void;
}

export interface IWorkerListDirectProps extends IWorkerListProps {
  mode: 'direct';
  open: boolean;
  dialogTitle?: string;
  onNoWorkerSelect?: () => void;
  onCancel: () => void;
}

export interface IWorkerListInlineProps extends IWorkerListProps {
  mode: 'inline';
}

interface IWorkerListState {
  searchText: string;
  loading: boolean;
  open: boolean;
}

const WorkerList = (props: IWorkerListButtonProps | IWorkerListDirectProps | IWorkerListInlineProps) => {
  const sxClasses = generateSxClasses(useTheme());
  const { profile } = props;

  const defaultColumns: ICustomColumnDefinition[] = [
    {
      key: 'person.pictureTakenUTC',
      displayControl: (r) => <WorkerPhoto personId={r['person.iecNumber']} photoTakenUTC={r['person.pictureTakenUTC']} diameter={80} />
    },
    { key: 'person.iecNumber' },
    { key: 'person.firstLegal' },
    { key: 'person.firstPreferred' },
    { key: 'person.middle' },
    { key: 'person.last' },
    {
      key: 'person.companyUnion.name',
      title: 'Union'
    },
    { key: 'activeCompanies' }
  ];

  const [key, display, iecNumberColumns] = ['person.iecNumber', 'displayName', '(identity.person.worker).(identity.person.iecnumber)'];
  const additionalColumns: string[] = [];

  const defaultWorkerListState: IWorkerListState = {
    searchText: props.quickFilter ?? '',
    loading: true,
    open: false
  };

  const [listState, setListState] = React.useState<IWorkerListState>(defaultWorkerListState);
  const [gridState, setGridState] = React.useState<undefined | GridStateType>(undefined);
  const [iecNumber, setIecNumber] = React.useState('');

  const setQuickSearch = (quickSearch: string) => {
    if (gridState == null) return;

    setListState(old => ({
      ...old,
      searchText: quickSearch
    }));

    setGridState(old => ({
      ...old!,
      quickTextFilter: quickSearch.length === 0 || listState.searchText === quickSearch ? undefined : quickSearch
    }));
  };

  const handleOpen = () => setListState((old) => ({
    ...old,
    open: true
  }));

  const handleClose = () => {
    setListState((old) => ({
      ...old,
      open: false
    }));

    if ((props.mode === 'button' && props.onCancel) || props.mode === 'direct') props.onCancel!();
  };

  const handleSelectRow: AutoGridRowClickEvent = (e) => {
    if (props.limitSelectCount === 1) {
      props.onWorkersSelected(e.updatedSelectedRows!);

      setListState(old => ({
        ...old,
        open: false
      }));
    } else {
      setGridState(old => ({
        ...old!,
        selectedRows: e.updatedSelectedRows
      }));
    }
  };

  const runSearch = (search: string, runGridState: GridStateType) => autoLoad({
    search,
    baseTableId: runGridState.baseTable as number,
    keyField: runGridState.keyField!,
    displayField: runGridState.displayField!,
    allFields: getAllFields(runGridState.displayColumns, runGridState.additionalColumns),
    fetchConfigRequest: props.fetchConfigRequest,
    createAlertBulk: props.createAlertBulk
  }).then((res) => {
    setQuickSearch(search);

    setListState(old => ({
      ...old,
      loading: false
    }));

    setGridState(old => ({
      ...old!,
      rowData: res,
      rowDataLastUpdate: moment().valueOf(),
      rowDataLoading: false
    }));

    if (props.onQuickFilter) props.onQuickFilter(search);
  });

  const handleSearchRun = (search: string): void => {
    if (listState.loading || gridState == null) return;

    if ((search ?? '') === '') {
      setQuickSearch('');

      setGridState(old => ({
        ...old!,
        rowData: {},
        rowDataLastUpdate: moment().valueOf(),
        quickTextFilter: undefined
      }));

      if (props.onQuickFilter) props.onQuickFilter(undefined);
    } else {
      setQuickSearch(search);

      setListState(old => ({
        ...old,
        loading: true
      }));

      setGridState(old => ({
        ...old!,
        rowDataLoading: true
      }));

      runSearch(search, gridState);
    }
  };

  React.useEffect(() => {
    if (gridState != null && listState.searchText !== props.quickFilter) {
      handleSearchRun(props.quickFilter ?? '');
    } else {
      setQuickSearch(props.quickFilter ?? '');
    }
  }, [props.quickFilter]);

  const handleGetByIdRun = (ids: number[]) => {
    const filterOverride = iecNumber?.trim();

    if (listState.loading || gridState == null) return;

    // If entered IEC Number is 0, or extrapolated array contains all 0s, short-circuit.
    // Passes back entered value and uses as `?filter=...`, unless value is explicitly 0.
    if (ids.filter(id => id === 0).length === ids.length) {
      if (filterOverride !== '0') props.onWorkersSelected(undefined, filterOverride);

      return setIecNumber('');
    }

    setListState(old => ({
      ...old,
      loading: true
    }));

    const search: IFilterDefinition = {
      baseFilter: {
        columnId: gridState.idSearchField.columnId,
        lookupPath: gridState.idSearchField.lookupPath || [],
        type: Array.isArray(ids) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
        firstParameter: ids
      },
      baseTableId: gridState.baseTable as number,
      name: ''
    };

    autoLoad({
      search,
      baseTableId: gridState.baseTable as number,
      keyField: gridState.keyField!,
      displayField: gridState.displayField!,
      allFields: getAllFields(gridState.displayColumns, gridState.additionalColumns),
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk
    }).then((res) => {
      const excluded: AutoLoadKeyDisplay = {};
      const overLimit: AutoLoadKeyDisplay = {};

      const selected: AutoLoadKeyDisplay = objectMap(res, (k, v, _, tot) => {
        const ret = {
          display: v[gridState.displayField!.columnAlias],
          rowData: v
        };

        if (((props.excludedIds ?? []).indexOf(+k) >= 0)) {
          excluded[k] = ret;

          return undefined;
        }

        if (props.limitSelectCount != null && tot > props.limitSelectCount) {
          overLimit[k] = ret;
        }

        return ret;
      });

      setListState(old => ({
        ...old,
        open: false,
        loading: false
      }));

      if (Object.keys(selected).length > 0) {
        props.onWorkersSelected(selected);
      } else {
        const alerts: string[] = [];
        const notFound = (Array.isArray(ids) ? ids : [ids]).filter(id => selected[id] == null && excluded[id] == null);

        if (notFound.length > 0) {
          alerts.push(`${notFound} IEC number${(notFound.length === 1 ? '' : 's')} not found: ${notFound.join(', ')}`);
        }

        if (Object.keys(overLimit).length > 0) {
          alerts.push(`The following worker(s) were not included based on the selection limit of ${props.limitSelectCount}: ${objectMapArray(overLimit, (personId, { display: displayName }) => `${displayName} (${personId})`).join(', ')}`);
        }

        if (Object.keys(excluded).length > 0) {
          alerts.push(`The following worker(s) were excluded from this selection: ${objectMapArray(excluded, (personId, { display: displayName }) => `${displayName} (${personId})`).join(', ')}`);
        }

        if (alerts.length > 0) {
          props.createAlertBulk(alerts.map(alert => ({
            autoDisplay: true,
            created: moment().format(settings.dateTimeFormatMoment),
            severity: 'warning',
            title: 'Workers Search',
            description: alert,
            page: 'Worker Search',
            viewed: false
          })));
        }
      }
    });
  };

  React.useEffect(() => {
    props.fetchConfigRequest(undefined, {
      key: 'identityWorkerList',
      action: (db) => {
        const baseTable = db.getTableByCombinedName('Identity.Worker').tableId;

        const [keyField, displayField, idSearchField] = db.mapSelectColumnsByNamesWithId(baseTable, [key, display, iecNumberColumns]);
        const newGridState = {
          baseTable,
          keyField,
          displayField,
          idSearchField,
          additionalColumns: db.mapSelectColumnsByNamesWithId(baseTable, additionalColumns),
          displayColumns: db.mapDisplayColumnsByNamesWithId(baseTable, defaultColumns),
          rowDataLastUpdate: moment().valueOf(),
          exportTitle: 'Worker Search',
          rowData: {}
        };

        if (props.quickFilter == null) {
          setGridState({
            ...newGridState,
            rowDataLoading: false
          });

          setListState(old => ({
            ...old,
            loading: false,
          }));
        } else {
          setGridState({
            ...newGridState,
            rowDataLoading: true
          });

          runSearch(props.quickFilter, {
            ...newGridState,
            rowDataLoading: true
          });
        }
      }
    });

    return () => { };
  }, []);

  const quickSearch = () => (
    <TextField
      id="quick-search"
      label="Select by IEC Number"
      margin="dense"
      fullWidth
      disabled={listState.loading}
      value={iecNumber}
      onChange={event => setIecNumber(event.target.value)}
      onKeyDown={(event) => {
        switch (event.key) {
          case 'Enter':
            handleGetByIdRun(stringToIdList(iecNumber));
            break;
          case 'Escape':
            setIecNumber('');
            break;
        }
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">
          <IconButton aria-label="search" onClick={() => handleGetByIdRun(stringToIdList(iecNumber))}>
            <Forward />
          </IconButton>
        </InputAdornment>
      }}
      helperText={props.limitSelectCount == null ? undefined : props.limitSelectCount === 1 ? 'Enter a single IEC Number' : `Enter up to ${props.limitSelectCount} IEC numbers`}
    />
  );

  const controlGridSizes = quickGrid(12, 6, 4, 3, 3);
  const displayGrid = () => gridState == null ? <LoadingScreen /> : (
    <Grid container spacing={3}>
      <Grid item {...controlGridSizes}>
        {props.limitSelectCount !== 1
          ? <Tooltip title="Add multiple workers by entering or pasting multiple ids with any character(s) in between numbers">
            {quickSearch()}
          </Tooltip>
          : quickSearch()
        }
      </Grid>
      <Grid item {...controlGridSizes}>
        <TextField
          id="quick-search"
          label="Search by Name"
          margin="dense"
          fullWidth
          disabled={listState.loading}
          value={listState.searchText}
          onChange={(event) => setQuickSearch(event.target.value)}
          onKeyDown={(event) => {
            switch (event.key) {
              case 'Enter':
                handleSearchRun(gridState.quickTextFilter ?? listState.searchText ?? '');
                break;
              case 'Escape':
                setQuickSearch(props.quickFilter ?? '');
                break;
            }
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton aria-label="search" onClick={() => handleSearchRun(gridState.quickTextFilter ?? '')}>
                <Search />
              </IconButton>
            </InputAdornment>
          }}
          helperText={props.quickFilter == null ? 'Press "Enter" or click search icon' : `Loaded rows for search of ${props.quickFilter}`}
        />
      </Grid>
      {profile.isTrainingFacility && profile.isAdmin ? (
        <Grid sx={{ ...sxClasses.buttonAlign }} item {...controlGridSizes}>
          <Button fullWidth variant="contained" size="small" onClick={props.onAddNew}>
            Add Worker
          </Button>
        </Grid>
      ) : undefined}
      {props.mode !== 'inline' && props.onNoWorkerSelect ? (
        <Grid sx={{ ...sxClasses.buttonAlign }} item {...controlGridSizes}>
          <Button fullWidth variant="contained" size="small" onClick={() => props.onNoWorkerSelect!()}>
            Select No Worker
          </Button>
        </Grid>
      ) : undefined}
      <Grid item xs={12}>
        <AutoGrid onRowClick={handleSelectRow} maxHeight={props.maxHeight ?? 'calc(100vh - 300px)'} {...gridState} />
      </Grid>
    </Grid>
  );

  return (
    <>
      {props.mode !== 'button' ? undefined : (
        <Button fullWidth variant="contained" size="small" disabled={props.disabled} onClick={handleOpen}>
          {props.buttonTitle}
        </Button>
      )}
      {props.mode === 'inline' ? (
        displayGrid()
      ) : (
        <Dialog open={listState.open || (props.mode === 'direct' && props.open)} onClose={handleClose} fullWidth maxWidth="lg">
          <DialogTitle>{props.dialogTitle ?? 'Select Company'}</DialogTitle>
          <DialogContent>{displayGrid()}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default compose(connect(mapProfileDbFromAppState, mapConfigFetchToProps))(WorkerList);
