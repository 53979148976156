import React from 'react';
import { IWorkerWebView } from './WebCourseTypes';
import WorkerPhoto from '../../identity/WorkerPhoto';
import { createStyles, withStyles } from '@mui/styles';
import { Theme, Grid, Typography, Button, FormControlLabel, Checkbox, useTheme } from '@mui/material';
import moment from 'moment';
import settings from 'src/abstracts/settings';

const generateSxClasses = (_theme: Theme) => {
  return {
    centerView: {
      maxWidth: 750,
      mx: 'auto'
    }
  };
};

export interface IProps {
  workerWebView: IWorkerWebView;
  onRunCourse: () => void;
}

interface IConnectedProps {
  classes: any;
}

const WorkerCourseDisplay = (props: IProps & IConnectedProps) => {
  const sxClasses = generateSxClasses(useTheme());
  const [acceptConditions, setAcceptConditions] = React.useState(false);

  const { workerWebView } = props;

  const stateButtonText = () => {
    if (workerWebView == null) return 'Loading...';

    if (workerWebView.assignment.completeDateUTC != null) return 'Review Course';

    if (workerWebView.assignment.startDateUTC != null) return 'Continue Course';

    return 'Start Course';
  };

  const launchState: 'run' | 'review' | 'historical' = workerWebView.assignment.completeDateUTC == null
    ? 'run'
    : moment().startOf('day').diff(moment.utc(workerWebView.assignment.completeDateUTC).local(), 'days') < 14
      ? 'review'
      : 'historical';

  const displayMessage = () => {
    switch (launchState) {
      case 'run': return 'If this is not you or you have any questions, please contact BASES @ (519) 383-1222';
      case 'review': return `Course completed ${moment.utc(workerWebView.assignment.completeDateUTC).local().format(settings.dateWeekdayTimeFormatMoment)}. You may open the course to review it for 2 weeks after completion, but need to register for a new course to be credited again.`;
      case 'historical': return `Course completed ${moment.utc(workerWebView.assignment.completeDateUTC).local().format(settings.dateWeekdayTimeFormatMoment)}. The course could only be opened for review for 2 weeks following completion. To be credited again, please register for a new course.`;
    }
  };

  return (
    <Grid sx={{ ...sxClasses.centerView }} container spacing={3}>
      <Grid key="header" item xs={12}>
        <Typography variant="h4">Worker Web Assignment</Typography>
      </Grid>
      <Grid key="photo" item xs={12}>
        <WorkerPhoto personId={workerWebView.iecNumber} readonly diameter={325} />
      </Grid>
      <Grid key="name" item xs={8}>
        <Typography variant="h5">{workerWebView.displayName}</Typography>
      </Grid>
      <Grid key="number" item xs={4}>
        <Typography variant="h6">{workerWebView.iecNumber}</Typography>
      </Grid>
      <Grid key="contact" item xs={6}>
        <Typography>{displayMessage()}</Typography>
      </Grid>
      <Grid hidden={workerWebView.assignment.completeDateUTC != null} key="verify" item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptConditions}
              onChange={() => setAcceptConditions(old => !old)}
              value="chkIdVerification"
              color="primary"
            />
          }
          label="ID Verification"
        />
      </Grid>
      <Grid key="contact" item xs={6}>
        <Typography>{
          workerWebView.assignment.completeDateUTC == null
            ? 'If this is not you or you have any questions, please contact BASES @ (519) 383-1222'
            : `Course completed ${moment.utc(workerWebView.assignment.completeDateUTC).local().format(settings.dateWeekdayTimeFormatMoment)}. You may open the course to review it, but need to register for a new course to be credited again.`
        }</Typography>
      </Grid>
      <Grid key="course" item xs={8}>
        <Typography variant="h6">{workerWebView.assignment.courseDisplay}</Typography>
        {workerWebView.assignment.completeDateUTC != null ? <Typography variant="h6">{workerWebView!.assignment.isPassed ? 'Passed' : 'Failed'} - {workerWebView.assignment.score}%</Typography> : null}
      </Grid>
      <Grid key="launch" item xs={4}>
        <Button disabled={!acceptConditions && workerWebView.assignment.completeDateUTC == null} hidden={launchState == 'historical'} key="button_run" onClick={props.onRunCourse} color="primary" variant="contained">
          {stateButtonText()}
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(createStyles({}))(WorkerCourseDisplay);
