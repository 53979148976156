import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Check, Pattern } from '@mui/icons-material';
import React, { ComponentType } from 'react';
import settings from 'src/abstracts/settings';
import AutoEditorField from '../AutoEditorField';
import { createStyles, withStyles } from '@mui/styles';
import { Database } from '../../../stores/database/class';
import { IAutoMultiProps, autoLoadMulti } from '../AutoLoad';
import LoadingScreen from '../../../components/common/LoadingScreen';
import { Dictionary, cloneDeep, flatten, isEqual, xor } from 'lodash';
import { mapProfileDbFromAppState } from '../../../stores/database/types';
import { IReferenceTableChange, getFieldOrder, lookupFilters } from '../AutoEditor';
import { IConnectedProps, getBoolCheckDisplay, handleNewlineCharacterInText, mapConfigFetchToProps } from '../AutoGrid';
import { arrayMapObject, hasAnyMatch, isInterface, objectEach, objectMap, quickGrid } from '../../../abstracts/DataroweHelpers';
import { ChangeMessage, IChangeSet, IDefaultNamedParameter, IDefaultValueParameter, autoChange, getAliasName } from '../AutoChange';
import { AutoEditorCache, IAutoEditField, IAutoEditItem, IAutoEditorStateExtension, IBulkEditValue, IDefaultStateExtension } from '../autoInterfaces';
import { ColumnType, IColumn, IColumnMap, ISelectColumnMap, ITable, ITableEditorSection, usedAlternateEditor } from '../../../stores/database/interfaces';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Theme, useTheme, Grid, Typography, Box, InputAdornment, IconButton, Chip, List, ListItem, Card, CardContent } from '@mui/material';

const generateSxClasses = (theme: Theme) => {
  return {
    alignmentDivider: {
      flex: '1 0 0'
    },
    bulkRecord: {
      borderRadius: theme.spacing(1),
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[300]
      },
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200]
      },
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2)
      }
    },
    bulkRecordBox: {
      maxHeight: 'calc(100vh - 300px)',
      overflow: 'auto'
    },
    bulkRecordInvalid: {
      color: theme.palette.mode === 'dark' ? theme.palette.error.main : '#d32f2f'
    },
    buttonMode: {
      marginLeft: theme.spacing(3)
    },
    chip: {
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(1)
    },
    chipList: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: 0
    },
    marginBottom: {
      marginBottom: theme.spacing(2)
    },
    secondaryText: {
      color: theme.palette.grey[500],
      fontStyle: 'italic',
      marginTop: theme.spacing(1)
    }
  };
};

interface IBulkCreatorProps {
  baseTable: string;
  cache?: AutoEditorCache;
  customEditor?: string;
  defaultValues?: (IDefaultValueParameter | IDefaultNamedParameter)[];
  referenceTableChanges?: Dictionary<IReferenceTableChange>;
  saveCache?: (cache: AutoEditorCache) => void;
  title?: string;
  onBulkCreate?: (bulkRecords: IBulkRecord[][], success: boolean, messages: ChangeMessage[]) => void;
  stateExtension?: IDefaultStateExtension;
}

export interface IBulkCreatorButtonProps extends IBulkCreatorProps {
  buttonTitle: string;
  disabled?: boolean;
  fullWidth?: boolean;
  mode: 'button';
  onCancel?: () => void;
  open?: boolean;
}

export interface IBulkCreatorDirectProps extends IBulkCreatorProps {
  mode: 'direct';
  onCancel: () => void;
  open: boolean;
}

export interface IBulkCreatorInlineProps extends IBulkCreatorProps {
  mode: 'inline';
  onCancel?: () => void;
}

interface IDisplayField {
  [columnId: string]: ISelectColumnMap;
}

interface IBulkRecord<T = any> {
  columnId: number;
  columnType: ColumnType;
  title: string;
  value: T;
  display: string;
  valid: boolean;
}

interface IBulkCreatorState {
  bulkFields: ITableEditorSection[];
  bulkRecords: IBulkRecord[][];
  cache?: AutoEditorCache;
  configurationMode: 'values' | 'fields';
  editItem?: IAutoEditItem;
  fieldOrder: ITableEditorSection[];
  loading: boolean;
  localState: IAutoEditorStateExtension;
  open: boolean;
  references: Dictionary<number>;
  table?: ITable;
}

interface IBulkCreatorTableIds {
  refId: string;
  displayName: string;
  keyName: string;
  parentAlias?: string | string[]
}

const BulkCreator = (props: (IBulkCreatorButtonProps | IBulkCreatorDirectProps | IBulkCreatorInlineProps) & IConnectedProps) => {
  const { profile } = props;

  const theme = useTheme();
  const sxClasses = generateSxClasses(theme);

  const defaultState: IBulkCreatorState = {
    bulkFields: [
      {
        key: 'auto',
        title: '',
        fieldOrder: []
      }
    ],
    bulkRecords: [],
    configurationMode: 'fields',
    fieldOrder: [],
    loading: false,
    localState: {
      selectedUserCompanyId: undefined
    },
    open: false,
    references: {}
  };

  const [state, setState] = React.useState(defaultState);

  // React.useEffect(() => {
  //   console.warn('BulkCreator props:', props);
  // }, [props]);

  // React.useEffect(() => {
  //   console.warn('BulkCreator state', state);
  // }, [state]);

  const formatData = (column: IColumn, value: any) => {
    switch (column.columnType) {
      case 'MultiLookup':
        return JSON.parse(value ?? '[]');
      case 'Json':
        return JSON.parse(value ?? '{}');
      default:
        return value;
    }
  };

  const loadReferenceTables = (db: Database) => {
    if (props.referenceTableChanges == null) return undefined;

    return objectMap(props.referenceTableChanges, (_, changes): {
      baseTable: ITable;
      referenceColumn: number;
      columns: Dictionary<number>;
    } => {
      const baseTable = db.getTableByCombinedName(changes.table);
      return {
        baseTable,
        referenceColumn: baseTable.columnNames[changes.reference.toLowerCase()],
        columns: arrayMapObject(changes.columns, (idx, col) => [col, baseTable.columnNames[col.toLowerCase()]])
      };
    });
  };

  const fieldInBulk = (columnId: number) => state.bulkFields[0]!.fieldOrder.includes(columnId);
  const valueInBulk = (value: any, columnId: number) => state.editItem![columnId].bulkValues!.filter((bulkValue) => bulkValue.value === value).length;
  const cartesian = (...args: any[]) => args.reduce((a, b) => a.flatMap((c: any) => b.map((d: any) => [c, d].flat())));
  const anyInvalidRecord = () => state.bulkRecords?.filter((bulkRecord) => bulkRecord.filter((record: IBulkRecord) => !record.valid).length).length !== 0;

  const validateBulkRecordField = (column: IColumn, value: any): boolean => {
    if ((value === '' || value === null || value === undefined) && column.required) {
      return false;
    }

    switch (column.columnType) {
      case 'BigInt':
      case 'Int':
      case 'SmallInt':
        return isNaN(parseInt(value, 10)) ? false : true;
    }

    return true;
  };

  const constructBulkRecords = (editItem: IAutoEditItem) => {
    const args: any[] = [];

    Object.keys(editItem).forEach((columnId, _index) => {
      const column: IColumn = state.table!.columns[columnId];

      if (fieldInBulk(+columnId)) {
        const bulkValues: any[] = [];

        editItem[columnId].bulkValues!.forEach((bulkValue) => {
          const bulkRecord: IBulkRecord = {
            columnType: column.columnType,
            columnId: column.columnId,
            title: column.title,
            value: bulkValue.value,
            display: bulkValue.display,
            valid: validateBulkRecordField(column, bulkValue.value)
          };

          bulkValues.push(bulkRecord);
        });

        args.push(bulkValues);
      } else {
        args.push([{
          columnType: column.columnType,
          columnId: column.columnId,
          title: column.title,
          value: editItem[columnId].editValue,
          display: editItem[columnId].displayValue ?? '',
          valid: validateBulkRecordField(column, editItem[columnId].editValue)
        }]);
      }
    });

    return args.length ? cartesian(...args) : [];
  };

  const loadData = () => {
    if (!state.loading) {
      setState((old) => ({
        ...old,
        loading: true
      }));

      props.fetchConfigRequest(undefined, {
        key: `bulkCreator_${props.baseTable}`,
        action: (db) => {
          const baseTable = db.getTableByCombinedName(props.baseTable);

          if (baseTable.primaryKeyId == null) {
            throw new Error(`Invalid Table ${props.baseTable} for BulkCreator - No Primary Key defined.`);
          }

          const references: Dictionary<number> = {};
          const displayFields: IDisplayField = {};

          const fieldOrder = getFieldOrder(baseTable, undefined, props.profile.isTrainingFacility, props.profile.isPartner);
          const fieldIds = flatten(fieldOrder.map((x) => x.fieldOrder));

          fieldIds.forEach((fieldId) => {
            const column = baseTable.columns[fieldId];

            if (column.referenceTableId != null && db.getTableById(column.referenceTableId).displayKeyId != null) {
              if (column.properties.displayMap != null) {
                displayFields[fieldId] = db.mapSelectColumnMap(baseTable.tableId, column.properties.displayMap);
              } else {
                displayFields[fieldId] = db.mapSelectLookupColumn(column);
              }
            }

            references[column.name] = column.columnId;
          });

          (props.defaultValues ?? []).forEach((x) => {
            if (isInterface<IDefaultNamedParameter>(x, 'columnName') && references[x.columnName] == null) {
              references[x.columnName] = baseTable.columnNames[x.columnName.toLowerCase()];
            }
          });

          const displayCol = db.mapColumnToSelectColumnMap(baseTable.columns[baseTable.displayKeyId!]);
          if (displayFields[baseTable.displayKeyId!] == null) {
            displayFields[baseTable.displayKeyId] = displayCol;
          }

          const selects: IAutoMultiProps[] = [];
          const cacheMap: AutoEditorCache = props.cache == null ? {} : cloneDeep(props.cache);
          const tableIds: Dictionary<IBulkCreatorTableIds> = {};
          const selectFields = db.getColumnsByTableId(baseTable.tableId, fieldIds);

          objectEach(baseTable.columns, (_key, column) => {
            if (column.properties.alternateEditor != null || selectFields.findIndex((x) => x.columnId === column.columnId) < 0 || ['Lookup', 'NestedLookup', 'MultiLookup'].indexOf(column.columnType) < 0 || column.referenceTableId == null || cacheMap[column.referenceTableId] != null) return;

            const refTable = db.getTableById(column.referenceTableId);
            if (refTable.primaryKeyId == null || refTable.displayKeyId == null) {
              throw new Error(`Invlaid RefTable ${refTable.title} for BulkCreator - Requires both Primary Key and Display Key.`);
            }

            const dictKey = `refTable_${db.getCombinedTableNameById(column.referenceTableId)}`;
            tableIds[dictKey] = {
              refId: column.referenceTableId.toString(),
              displayName: getAliasName(refTable.columns[refTable.displayKeyId].name),
              keyName: getAliasName(refTable.columns[refTable.primaryKeyId].name)
            };

            const selectColumns: IColumnMap[] = [
              {
                columnId: refTable.primaryKeyId,
                lookupPath: []
              },
              {
                columnId: refTable.displayKeyId,
                lookupPath: []
              }
            ];

            if (column.columnType === 'NestedLookup') {
              const parentIdField = Object.keys(refTable.columns).find((x) => refTable.columns[x].referenceTableId === refTable.tableId);

              selectColumns.push({
                columnId: +parentIdField!,
                lookupPath: []
              });

              tableIds[dictKey].parentAlias = getAliasName(refTable.columns[parentIdField!].name);
            } else if ((column.properties.listEditorParentColumns?.length ?? 0) > 0) {
              const parentFields: string[] = [];

              column.properties.listEditorParentColumns!.forEach((parentColumn) => {
                selectColumns.push(parentColumn);
                parentFields.push(db.mapSelectColumnMap(column.referenceTableId!, parentColumn).columnAlias);
              });

              tableIds[dictKey].parentAlias = parentFields;
            }

            selects.push({
              baseTableId: refTable.tableId,
              columns: selectColumns,
              keyField: db.mapKeySelectColumnByIds(refTable.primaryKeyId),
              resultKey: dictKey,
              search: lookupFilters(db, column, props.defaultValues?.find(x => (isInterface<IDefaultNamedParameter>(x, 'columnName') ? references[x.columnName] : x.columnId) === column.columnId))
            });
          });

          const editItem: IAutoEditItem = {};

          if (selects.length > 0) {
            autoLoadMulti({ multi: selects, fetchConfigRequest: props.fetchConfigRequest, createAlertBulk: props.createAlertBulk }).then((res) => {
              objectEach(res, (key, result) => {
                if (key === props.baseTable) return;

                cacheMap[tableIds[key].refId] = {
                  displayAlias: tableIds[key].displayName,
                  keyAlias: tableIds[key].keyName,
                  parentAlias: tableIds[key].parentAlias,
                  rows: result,
                  tableAlias: key
                };
              });

              selectFields.forEach(({ columnId }) => {
                const aliasName = getAliasName(baseTable.columns[columnId].name);
                const defaultValue = props.defaultValues?.find(x => (isInterface<IDefaultNamedParameter>(x, 'columnName') ? references[x.columnName] : x.columnId) === columnId);

                editItem[columnId] = {
                  editValue: formatData(baseTable.columns[columnId], res[props.baseTable]?.[0]?.[aliasName] ?? defaultValue?.newValue),
                  originalValue: formatData(baseTable.columns[columnId], res[props.baseTable]?.[0]?.[aliasName]),
                  isChanged: defaultValue != null,
                  error: '',
                  warning: '',
                  readonly: defaultValue?.readonly,
                  bulkValues: []
                };
              });

              setState((old) => ({
                ...old,
                cache: cacheMap,
                displayName: props.title ?? (baseTable.displayKeyId == null ? '' : res[props.baseTable]?.[0]?.[displayCol.columnAlias] ?? 'New item'),
                editable: fieldIds.some((x) => !baseTable.columns[x].readonly && (!baseTable.columns[x].editSecurable || profile.isTrainingFacility)),
                failedSave: false,
                loading: false,
                referenceTables: loadReferenceTables(db),
                table: baseTable,
                editItem,
                fieldOrder,
                references,
              }));

              if (props.saveCache) props.saveCache(cacheMap);
            });
          } else {
            selectFields.forEach(({ columnId }) => {
              const def = props.defaultValues?.find((x) => (isInterface<IDefaultNamedParameter>(x, 'columnName') ? references[x.columnName] : x.columnId) === columnId);

              editItem[columnId] = {
                editValue: def?.newValue,
                originalValue: null,
                isChanged: def != null,
                error: '',
                warning: '',
                readonly: def?.readonly,
                bulkValues: []
              };
            });

            setState((old) => ({
              ...old,
              cache: cacheMap,
              displayName: props.title ?? 'Item',
              editable: fieldIds.some((x) => !baseTable.columns[x].readonly && (!baseTable.columns[x].editSecurable || profile.isTrainingFacility)),
              failedSave: false,
              loading: false,
              referenceTables: loadReferenceTables(db),
              table: baseTable,
              editItem,
              fieldOrder,
              references
            }));
          }
        }
      });
    }
  };

  React.useEffect(loadData, []);

  const validateValue = (value: any, column: IColumn, additionalError?: string, additionalWarning?: string): { error: string; warning: string } => {
    const defaultWarning = additionalWarning ?? '';

    if (additionalError != null) {
      return {
        error: additionalError,
        warning: defaultWarning
      };
    }

    if (column.properties.alternateEditor != null) {
      // -TODO-: any custom validation for alternate editors
    }

    if (valueInBulk(value, column.columnId)) {
      return {
        error: `Value has already been added to ${column.title} Value(s)`,
        warning: defaultWarning
      };
    }

    if ((value === '' || value === null || value === undefined) && column.required) {
      return {
        error: `${column.title} must not be blank`,
        warning: defaultWarning
      };
    }

    switch (column.columnType) {
      case 'BigInt':
      case 'Int':
      case 'SmallInt':
        if (value === '' || value === null || (value === undefined && !column.required)) {
          return {
            error: '',
            warning: defaultWarning
          };
        }

        return {
          error: isNaN(parseInt(value, 10)) ? `${column.title} must be numeric` : '',
          warning: defaultWarning
        };
    }

    return {
      error: '',
      warning: defaultWarning
    };
  };

  const isValueChanged = (newValue: any, oldValue: any, column: IColumn): boolean => {
    if ((newValue === null || newValue === undefined) !== (oldValue === null || oldValue === undefined)) return true;

    if ((newValue === null || newValue === undefined) && (oldValue === null || oldValue === undefined)) return false;

    switch (column.columnType) {
      case 'String':
        return newValue.localeCompare(oldValue) !== 0;
      case 'Json':
        return !isEqual(newValue, oldValue);
      case 'MultiLookup':
        return xor(newValue, oldValue).length > 0;
      default:
        return newValue !== oldValue;
    }
  };

  const updateValue = (value: any, column: IColumn, current: IAutoEditField, additionalError?: string, additionalWarning?: string): IAutoEditField => ({
    ...validateValue(value, column, additionalError, additionalWarning),
    editValue: value,
    originalValue: current.originalValue,
    isChanged: isValueChanged(value, current.originalValue, column),
    bulkValues: current.bulkValues
  });

  const handleValueUpdated = (value: any, column: IColumn, displayValue?: string, additionalError?: string, additionalWarning?: string, localStateUpdate?: any) => {
    const editItem: IAutoEditItem = state.editItem == null ? {} : cloneDeep(state.editItem);

    editItem[column.columnId] = {
      ...updateValue(value, column, editItem[column.columnId], additionalError, additionalWarning),
      displayValue: displayValue ?? value as string
    };

    const bulkRecords: IBulkRecord[][] = constructBulkRecords(editItem);

    setState((old) => {
      return {
        ...old,
        failedSave: hasAnyMatch(editItem, (item) => item.error.length > 0),
        localState: {
          ...old.localState,
          ...(localStateUpdate ?? {})
        },
        bulkRecords,
        editItem,
      };
    });
  };

  const handleAddValueToBulkValues = (event: React.MouseEvent, columnId: number) => {
    event.stopPropagation();

    const editItem: IAutoEditItem = state.editItem == null ? {} : cloneDeep(state.editItem);

    editItem[columnId].bulkValues!.push({
      value: editItem[columnId].editValue,
      display: editItem[columnId].displayValue ?? ''
    });

    editItem[columnId] = {
      ...editItem[columnId],
      editValue: undefined,
      isChanged: false,
      error: '',
      warning: ''
    };

    const bulkRecords: IBulkRecord[][] = constructBulkRecords(editItem);

    setState((old) => ({
      ...old,
      bulkRecords,
      editItem
    }));
  };

  const handleRemoveValueFromBulkValues = (columnId: number, index: number) => {
    const editItem: IAutoEditItem = state.editItem == null ? {} : cloneDeep(state.editItem);

    editItem[columnId].bulkValues!.splice(index, 1);

    const bulkRecords: IBulkRecord[][] = constructBulkRecords(editItem);

    setState((old) => ({
      ...old,
      bulkRecords,
      editItem
    }));
  };

  const handleOpen = () => {
    if (props.mode === 'button') {
      setState((old) => ({
        ...old,
        open: true
      }));
    }
  };

  const handleClose = () => {
    if (props.mode !== 'inline') {
      if (props.onCancel != null) props.onCancel();

      if (props.mode === 'button') {
        setState((old) => ({
          ...old,
          open: false
        }));
      }
    }
  };

  const handleConfigureBulkFields = () => {
    setState((old) => ({
      ...old,
      configurationMode: old.configurationMode === 'fields' ? 'values' : 'fields'
    }));
  };

  const handleSave = () => {
    const changeSets: IChangeSet[] = state.bulkRecords.map((bulkRecord) => ({
      tableId: state.table!.tableId,
      type: 'Create',
      action: 'BulkCreatorCreate',
      changes: bulkRecord.map((bulkRecordField) => ({
        columnId: bulkRecordField.columnId,
        newValue: bulkRecordField.value
      }))
    }));

    autoChange({
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk,
      page: props.ui.title,
      title: `Bulk Create ${state.table!.title} Record(s)`,
      description: `Bulk Create of ${state.bulkRecords.length} Row(s)`,
      changes: {
        action: 'Create',
        changeSets
      }
    }).then((res) => {
      if (props.onBulkCreate) props.onBulkCreate(state.bulkRecords, res.success, res.messages);

      const editItem: IAutoEditItem = state.editItem == null ? {} : cloneDeep(state.editItem);

      Object.keys(editItem).forEach((columnId) => {
        editItem[columnId] = {
          ...editItem[columnId],
          bulkValues: [],
          editValue: undefined,
          error: '',
          isChanged: false,
          warning: ''
        };
      });

      setState((old) => ({
        ...old,
        bulkFields: [{
          key: 'auto',
          title: '',
          fieldOrder: []
        }],
        bulkRecords: [],
        configurationMode: 'fields',
        open: false,
        editItem
      }));
    });
  };

  const displayTitle = (): string => {
    if (props.title != null) return `Bulk Create ${props.title}`;

    if (state.table == null) return 'Loading...';

    return `Bulk Create ${state.table.title} Records`;
  };

  const toggleField = (columnId: number) => {
    const bulkFields = state.bulkFields;
    const editItem: IAutoEditItem = state.editItem == null ? {} : cloneDeep(state.editItem);

    if (!bulkFields[0].fieldOrder.includes(columnId)) {
      bulkFields[0].fieldOrder.push(columnId);
    } else {
      bulkFields[0].fieldOrder = bulkFields[0].fieldOrder.filter(id => id != columnId);
    }

    editItem[columnId] = {
      ...editItem[columnId],
      bulkValues: [],
      editValue: undefined,
      error: '',
      isChanged: false,
      warning: ''
    };

    setState((old) => ({
      ...old,
      bulkRecords: [],
      bulkFields,
      editItem
    }));
  };

  const toggleBulkField = (columnId: number, hidden: boolean, disabled: boolean = false) => {
    if (hidden) return undefined;

    const sizes = quickGrid(12, 6, 4, 3, 3);
    const column = state.table!.columns[columnId];

    return (
      <Grid key={`toggleBulkField_${columnId}`} item {...sizes}>
        <Button fullWidth variant="contained" onClick={() => toggleField(columnId)} disabled={disabled}>{column.title}</Button>
      </Grid>
    );
  };

  const autoEditorField = (columnId: number) => {
    if (fieldInBulk(columnId)) return undefined;

    return (
      <AutoEditorField
        key={`autoEditorField_${columnId}`}
        {...state.editItem![columnId]}
        editId={undefined}
        item={state.editItem!}
        columnReferences={state.references}
        field={state.table!.columns[columnId]}
        valueCache={state.cache}
        onValueUpdated={handleValueUpdated}
        disabled={state.table!.columns[columnId].editSecurable && !profile.isTrainingFacility}
        readonly={state.editItem![columnId].readonly}
        stateExtension={props.stateExtension}
        localState={state.localState}
      />
    );
  };

  const displayBulkValue = (columnId: number, value: any) => {
    if (value == null) return;

    const column = state.table!.columns[columnId];

    switch (column.columnType) {
      case 'Bool':
        return getBoolCheckDisplay(value);
      case 'Date':
        return moment(value).format(settings.dateFormatMoment);
      case 'Time':
        return column.name.toLowerCase().endsWith('utc') ? moment.utc(`1900-01-01 ${value}`).local().format(settings.timeFormatMoment) : moment(`1900-01-01 ${value}`).format(settings.timeFormatMoment);
      case 'DateTime':
        return column.name.toLowerCase().endsWith('utc') ? moment.utc(value).local().format(settings.dateTimeFormatMoment) : moment(value).format(settings.dateTimeFormatMoment);
      case 'Money':
        return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(value);
      default:
        return handleNewlineCharacterInText(value);
    }
  };

  const renderBulkValues = (columnId: number, bulkValues: IBulkEditValue[]) => {
    if (!bulkValues.length) return <Typography key={`renderBulkValuesNone_${columnId}`} sx={{ ...sxClasses.secondaryText }}>None</Typography>;

    return (
      <Box key={`renderBulkValues_${columnId}`} sx={{ ...sxClasses.chipList }}>
        {bulkValues.map((bulkValue, index) => <Chip sx={{ ...sxClasses.chip }} key={`renderBulkValuesField_${columnId}_${index}`} label={bulkValue.display ?? displayBulkValue(columnId, bulkValue.value)} color="primary" onDelete={() => handleRemoveValueFromBulkValues(columnId, index)} />)}
      </Box>
    );
  };

  const autoEditorBulkField = (columnId: number) => {
    if (!fieldInBulk(columnId)) return undefined;

    const column = state.table!.columns[columnId];
    const editItem = state.editItem![columnId];

    return (
      <Grid key={`autoEditBulkFieldContainer_${columnId}`} item xs={12}>
        <Grid container spacing={3}>
          <AutoEditorField
            key={`autoEditorBulkField_${columnId}`}
            {...state.editItem![columnId]}
            editId={undefined}
            item={state.editItem!}
            columnReferences={state.references}
            field={state.table!.columns[columnId]}
            valueCache={state.cache}
            onValueUpdated={handleValueUpdated}
            disabled={state.table!.columns[columnId].editSecurable && !profile.isTrainingFacility}
            readonly={state.editItem![columnId].readonly}
            localState={state.localState}
            sizes={{ xs: 12, sm: 6 }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  onClick={(event) => handleAddValueToBulkValues(event, columnId)}
                  disabled={(typeof editItem.error !== 'undefined' && editItem.error !== '') || editItem.editValue == null}
                  title='Add '
                >
                  <Check />
                </IconButton>
                {
                  column.columnType === 'Date'
                    ? <IconButton>
                      <Pattern />
                    </IconButton>
                    : undefined
                }
              </InputAdornment>
            }
          />
          <Grid item xs={12}>
            <label>{column.title} Value(s):</label>
            {renderBulkValues(columnId, editItem.bulkValues!)}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const disabledBulkField = (columnId: number) => {
    const column = state.table!.columns[columnId];

    if (column.bulkSecurable) return true;

    if (!column.readonly && usedAlternateEditor(column.properties) == null) {
      switch (column.columnType) {
        case 'Bool':
        case 'Json':
        case 'Lookup':
        case 'MultiLookup':
          return true;
        default:
          return false;
      }
    }

    switch (usedAlternateEditor(column.properties, column.readonly)) {
      case 'CompanySelect':
      case 'UserCompanySelect':
      case 'WorkerSelect':
        return false;
      default:
        return true;
    }
  };

  const displaySection = (section: ITableEditorSection) => {
    const allFieldsInBulk = section.fieldOrder.filter((columnId) => !fieldInBulk(columnId)).length === 0;

    if (allFieldsInBulk) {
      return (
        <Grid key={`displaySectionHelper_${section.key}`} item xs={12}>
          <Typography sx={{ ...sxClasses.secondaryText }}>{state.configurationMode === 'fields' ? 'Select fields below to move them to "Single Fields" section.' : 'Click "Configure Fields" and select at least one field from the fields available.'}</Typography>
        </Grid>
      );
    }

    return (
      <React.Fragment key={`displaySection_${section.key}`}>
        {section.title.length > 0 ? (
          <Grid key={`section_${section.key}`} item xs={12}>
            <Typography variant="h6">{section.title}</Typography>
          </Grid>
        ) : undefined}
        {section.fieldOrder.map((columnId) => state.configurationMode === 'fields' ? toggleBulkField(columnId, fieldInBulk(columnId), disabledBulkField(columnId)) : autoEditorField(columnId))}
      </React.Fragment>
    );
  };

  const displayBulkSection = (section: ITableEditorSection) => {
    if (!section.fieldOrder.length) {
      return (
        <Grid key={`displayBulkSectionHelper_${section.key}`} item xs={12}>
          <Typography sx={{ ...sxClasses.secondaryText }}>{state.configurationMode === 'fields' ? 'Select fields above to move them to "Bulk Fields" section.' : 'Click "Configure Fields" and select at least one field from the fields available.'}</Typography>
        </Grid>
      );
    }

    return (
      <React.Fragment key={`displayBulkSection_${section.key}`}>
        {section.fieldOrder.map((columnId) => state.configurationMode === 'fields' ? toggleBulkField(columnId, !fieldInBulk(columnId), disabledBulkField(columnId)) : autoEditorBulkField(columnId))}
      </React.Fragment>
    );
  };

  const displayDefaultEditor = () => {
    return (
      state.loading ? (
        <LoadingScreen key="default-editor-loading-config" />
      ) : (
        <Grid key="root-editor-grid" container spacing={3}>
          {state.table == null ? (
            <LoadingScreen key="default-editor-loading-table" />
          ) : (
            <React.Fragment key="default-editor-bulk-create">
              {state.fieldOrder.map((sec) => displaySection(sec))}
            </React.Fragment>
          )}
        </Grid>
      )
    );
  };

  const displayBulkEditor = () => {
    return (
      state.loading ? (
        <LoadingScreen key="bulk-editor-loading-config" />
      ) : (
        <Grid key="bulk-editor-grid" container spacing={3}>
          {state.table == null ? (
            <LoadingScreen key="bulk-editor-loading-table" />
          ) : (
            <React.Fragment key="bulk-editor-bulk-create">
              {state.bulkFields.map((sec) => displayBulkSection(sec))}
            </React.Fragment>
          )}
        </Grid>
      )
    );
  };

  const displayBulkRecord = (bulkRecord: IBulkRecord[], index: number) => {
    return (
      <Card key={`bulkRecord_${index}`} sx={{ ...sxClasses.bulkRecord }}>
        <CardContent>
          <List dense>
            {bulkRecord.map((record) => {
              return !fieldInBulk(record.columnId) ? undefined : (
                <ListItem sx={record.valid ? undefined : { ...sxClasses.bulkRecordInvalid }} key={`bulkRecord_${index}_${record.columnId}`}>{`${record.title}: ${record.display ?? displayBulkValue(record.columnId, record.value)}`}</ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    );
  };

  const displayBulkReview = () => {
    if (state.configurationMode === 'values' && state.bulkRecords.length) {
      return state.bulkRecords.map((bulkRecord, index) => displayBulkRecord(bulkRecord, index));
    }

    return (
      <Typography sx={{ ...sxClasses.secondaryText }}>{state.configurationMode === 'fields' ? 'Click "Configure Values" to review Bulk Records.' : 'Configure at least one value for each field in the "Single Fields" or "Bulk Fields" sections.'}</Typography>
    );
  };

  const displayEditor = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography sx={{ ...sxClasses.marginBottom }}>Single Fields</Typography>
                  {displayDefaultEditor()}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography sx={{ ...sxClasses.marginBottom }}>Bulk Fields</Typography>
                  {displayBulkEditor()}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={4}>
          <Typography sx={{ ...sxClasses.marginBottom }}>Review Section</Typography>
          <Box sx={{ ...sxClasses.bulkRecordBox }}>
            {displayBulkReview()}
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {props.mode === 'button' ? (
        <Button sx={{ ...sxClasses.buttonMode }} fullWidth={props.fullWidth} variant="contained" disabled={props.disabled} size="small" onClick={handleOpen}>
          {props.buttonTitle}
        </Button>
      ) : undefined}
      {props.mode === 'inline' ? (
        displayEditor()
      ) : (
        <Dialog open={state.open || (props.mode === 'direct' && props.open)} onClose={handleClose} fullWidth maxWidth="xl">
          <DialogTitle>{displayTitle()}</DialogTitle>
          <DialogContent>{displayEditor()}</DialogContent>
          <DialogActions>
            <Button onClick={handleConfigureBulkFields} color="primary">
              Configure {state.configurationMode === 'fields' ? 'Values' : 'Fields'}
            </Button>
            <Box sx={{ ...sxClasses.alignmentDivider }} />
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" disabled={state.configurationMode === 'fields' || !state.bulkRecords.length || anyInvalidRecord()}>
              {`Create ${state.bulkRecords.length} Record(s)`}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default compose<ComponentType<(IBulkCreatorButtonProps | IBulkCreatorDirectProps | IBulkCreatorInlineProps)>>(withStyles(createStyles({})), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(BulkCreator);
